body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  /*overflow: hidden;*/
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  width:100%;
  background-color: #f8f8f8;
}

.modal-backdrop {
  z-index: 2000;
}
.modal {
  z-index: 2020;
}
.rs-picker-menu {
  z-index: 2030;
}
/** THIS IS NOT A SOLUTION, JUST A WORKAROUND FOR GooglePlacesAutocomplete **/
.css-26l3qy-menu {
  z-index: 2040 !important;
}

.rs-picker-toggle-wrapper {
  display: block;
}