.StepDetail {
}

.StepDetail .text {
    padding: 3px;
}
.StepDetail .text .save {
    margin-top: 3px;
    text-align: right;
}
.StepDetail .text .save button {
    margin-left: 3px;
}
.StepDetail .text textarea {
    width: 100%;
    height: 120px;
}
.StepDetail .text .styled {
    white-space: pre;
    padding: 3px 10px;
}
.StepDetail .text .styled a {
    color: #000;
}


.StepDetail .buttons {
    margin-top: 5px;
    margin-right: 5px;
    text-align: right;
}
.StepDetail .buttons svg {
    transition: all .2s ease-in-out;
    margin: 4px;
    cursor: pointer;
    width: 26px;
    height: 26px;
}
.StepDetail .buttons svg.red {
    transition: all .1s ease-in-out;
    margin: 0;
    color: red;
    width: 34px;
    height: 34px;
}

.StepDetail .photos {
    margin: 0 -15px;
}

.StepDetail .photos img {
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
}
.rs-carousel, .rs-carousel .rs-carousel-slider-item {
    background-color: #DDD;
}