
.TripSchedule .detail .inner {
    margin-top: 8px;
    transition: all 0 ease-in-out;
    overflow: hidden;
    height: 0;
    border: none;
    background-color: #FFF;
    border: 1px solid #bacf02;
    border-radius: 8px;
}

.TripSchedule .selected .detail .inner {
    margin-top: 8px;
    transition: all .5s ease-in-out;
    overflow: hidden;
    height: auto;
    min-height: 300px;
}
