.map {
    height: calc(50vh - 50px - 66px);
}
.TripMap {
    height: 100%;
    position: relative;
    display: flex;
}
@media (min-width: 576px) {
    .map {
        height: calc(100vh - 67px);
        position: sticky;
        top: 67px;
    }
    .TripMap {
        border-right: 1px solid #666;
    }
}