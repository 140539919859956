
.TripSchedule .WayDetail .text {
    padding: 3px;
}
.WayDetail .text .save {
    margin-top: 3px;
    text-align: right;
}
.WayDetail .text .save button {
    margin-left: 3px;
}

.WayDetail .buttons {
    margin-top: 5px;
    margin-right: 5px;
    text-align: right;
}
.WayDetail .buttons svg {
    transition: all .2s ease-in-out;
    margin: 4px;
    cursor: pointer;
    width: 26px;
    height: 26px;
}
.WayDetail .text .styled {
}
