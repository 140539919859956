
.AutoAdd .add {
    cursor: pointer;
    width: 36px;
    height: 36px;
    margin: 5px 8px 12px auto;
}
.AutoAdd .add svg {
    width: 36px;
    height: 36px;
}