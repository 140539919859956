.TripSchedule {
    position: relative;
    background-color: #f8f8f8;
}

.TripSchedule div.TripSchedule-list > div  {
    background-image: url('schedulebg.gif');
    background-repeat: repeat-y;
    background-position-y: center;
    background-position-x: 46px;
    background-color: #f8f8f8;
}
.TripSchedule div.TripSchedule-list > div:first-child {

    background-repeat: no-repeat;
    background-position-y: 50px;
}
.TripSchedule div.TripSchedule-list > div:last-child {
    background-repeat: no-repeat;
    background-position-y: -260px;
}

.TripSchedule .backicon {
    position: absolute;
    top: 7px;
    left: 12px;
    border: 1px solid #bacf02;
    background-color: #fff;
    z-index: 10;
    width: 22px;
    height: 22px;
    border-radius: 3px;
    display: none;
    cursor: pointer;
}
.TripSchedule .backicon svg {
    margin: -7px 0 0 2px;
}

.TripSchedule.limited .backicon {
    display: block;
}


.TripSchedule div.TripSchedule-list > div {
    padding: 0;
}
.TripSchedule.all div.TripSchedule-list > div {
    transition: all .5s ease-in-out;
}
.TripSchedule.limited div.TripSchedule-list > div, 
.TripSchedule.limited div.AutoAdd {
    max-height: 0;
    overflow: hidden;
    border: none;
}
.TripSchedule.limited div.TripSchedule-list > div.selected {
    max-height: fit-content;
    overflow: visible;
}

.TripSchedule .lmgrid {
    display: grid;
    grid-template-columns: 80px auto;  
    grid-template-rows: auto auto;
    align-items: center;
    padding: 8px 16px;
}
.TripSchedule > div > div:first-child > .lmgrid {
    padding-top: 12px
}


.TripSchedule .step .image {
    transition: all .2s ease-in-out;
    border-radius: 50%;
    border: 3px solid grey;
    width: 60px;
    height: 60px;
    background-color: #EFEFEF;
    background-repeat: no-repeat;
    background-size: 60px 60px;
    cursor: pointer;
}

.TripSchedule .step.selected .image {
    border-color: #bacf02;
}
.TripSchedule .step.start .image {
    border-radius: 5px 5px 30px 30px;
    height: 50px;
}
.TripSchedule .step.end .image {
    border-radius: 30px 30px 5px 5px;
    height: 50px;
}

.TripSchedule .step.selected .image {
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
    margin: -5px;
}
.TripSchedule .selected h2, .TripSchedule .selected h3, .TripSchedule .selected h4, .TripSchedule .selected p {
    color: #bacf02;
}

.TripSchedule .step .text {
    cursor: pointer;
    width: auto;
    overflow: hidden;
}

.TripSchedule .step p, 
.TripSchedule .step h3 {
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--bs-body-color);
}
.TripSchedule .step h4 {
    font-size: 16px;
    margin: 0 0 2px 0;
    font-weight: 800;
}
.TripSchedule .step h2 {
    font-size: 21px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.TripSchedule .step ul, .TripSchedule .step li {
    font-size: 12px;
    margin: 0;
    padding: 0;
    list-style: none;
}



.TripSchedule .step.selected .text {
    cursor: pointer;
    width: auto;
    overflow: hidden;
}
.TripSchedule .step.selected p, 
.TripSchedule .step.selected h2, 
.TripSchedule .step.selected h3 {
    white-space: normal;
    overflow: auto;
    text-overflow: ellipsis;
}


.TripSchedule .way {
    position: relative;
}
.TripSchedule .way .icon {
    border-radius: 50%;
    border: 2px solid grey;
    width: 40px;
    height: 40px;
    padding: 3px 0 0 7px;
    margin: 0 0 0 10px;
    background-color: #FFF;
    cursor: pointer;
}
.TripSchedule .way .line {
    border: 2px solid grey;
    border-width: 2px 0 0 0;
    width: 20px;
    position: absolute;
    top: 36px;
    left: 70px;
}
.TripSchedule .way.selected .line, 
.TripSchedule .way.selected .icon {
    border-color: #bacf02;
}
.TripSchedule .way .text {
    cursor: pointer;
    width: auto;
    overflow: hidden;
    padding: 4px 0;
}

.TripSchedule .way h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 800;
}
.TripSchedule .way h3 {
    font-size: 18px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.TripSchedule .way h4 {
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.TripSchedule .way p {
    font-size: 14px;
    margin:  0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.TripSchedule .way.selected .text {
    cursor: pointer;
    width: auto;
    overflow: hidden;
}
.TripSchedule .way.selected h3, 
.TripSchedule .way.selected h4,
.TripSchedule .way.selected p {
    white-space: normal;
    overflow: auto;
    text-overflow: ellipsis;
}

.TripSchedule .detail {
    grid-column: span 2;
    margin-top: 0;
    transition: all 0 ease-in-out;
    overflow: hidden;
    height: 0;
}

.TripSchedule .selected .detail {
    margin-top: 8px;
    transition: all .5s ease-in-out;
    overflow: hidden;
    height: auto;
    min-height: 300px;
}
