.Add {
    /*display: grid;
    grid-template-columns: auto 40px;  */
    margin: 10px;
}
.Add .rdt.form-control {
     padding: 0;
}
.Add .rdt .form-control {
    border: none;
}
.Add .del {
    margin: 3px;
    color: grey;
}
.Add .addbuttons {
    text-align: right;
}
.Add .addbuttons button {
    margin-left: 3px;
}