.TripTitel {
    width: 100%;
    display: grid;
    grid-template-columns: auto 50px;  
    cursor: pointer;
}
.TripTitel h1  {
    font-size: 1.8rem;
    margin: 6px 0 0 16px;
    color: #212529;
}
.TripTitel.selected h1  {
    color: #bacf02;
    position: relative;
}

.TripTitel h1 .icon  {
    display: none
}
.TripTitel.selected h1 .icon  {
    display: block;
    position: absolute;
    cursor: pointer;
    padding: 0 3px 3px 3px;
    top: 0;
    right: 0;
}

.TripTitel h2  {
    font-size: calc(1.1rem);
    margin: 16px 0 0 16px;
    color: #565656;
}
.TripTitel p  {
    margin: 16px 0 0 16px;
}


.TripTitel .tips h3  {
    font-size: calc(1.0rem);
    font-weight: 600;
    margin: 16px 0 0 16px;
    color: #565656;
}
.TripTitel .tips p  {
    font-size: calc(0.8rem);
    margin: 4px 0 0 16px;
}
.TripTitel .tips {
    padding-bottom: 16px;
}



.TripTitel .share {
    padding:8px;
}


.showurl .url {
    display: flex;
}
.showurl .spinner {
    display: none;
}
.showspinner .url {
    display: none;
}
.showspinner .spinner {
    display: block;
}