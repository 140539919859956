.DetailTitle {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto 50px;  
    cursor: pointer;
}
.DetailTitle .icon {
    border: 1px solid #bacf02;
    display: block; 
    float: left;
    width: 40px;
    height: 40px;
    margin: 3px;
    border-radius: 4px;
    background-color: #FFF;
}
.DetailTitle .icon svg {
    margin: 8px 0 0 8px;
}
.DetailTitle .text {
    border: 1px solid #bacf02;
    border-width: 1px 0;
    display: block; 
    float: left;
    width: 80px;
    height: 40px;
    margin: 3px 0;
    background-color: #FFF;
    font-size: 18px;
}
.DetailTitle .text div {
    margin-top: 6px;
    text-align: center;
}
.DetailTitle .text svg {
    margin-top: -4px;
}

.DetailTitle .icon.up {
    border-radius: 4px 0 0 4px;
}
.DetailTitle .icon.down {
    border-radius: 0 4px 4px 0;
}